import React from "react"

import { Blank } from "grommet-icons"

const IconDisc = props => (
  <Blank
    viewBox="0 0 250 245"
    {...props}
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <path
      d="M96.796 159.27c-12 19.634-23.834 39.11-35.834 58.818 21.334 13.491 44.167 20.533 69.5 19.3-.416-22.55-.833-44.742-1.25-67.075-12.583 1.466-23.916-.425-32.416-11.042zm101.416-126c-.833-.74-1.416-1.3-2.083-1.832-18.417-14.967-39.583-22.784-63-24.959-1.417-.133-1.917.267-2.083 1.709-2.084 19.666-4.25 39.308-6.417 58.958-.333 2.867-.583 5.742-.917 8.883 13.334-3.325 23.5 2.1 32.5 10.984 14-17.942 27.917-35.759 42-53.742zm-72.5 131.126c27.584 0 47.417-24.917 40.917-51.5-5.917-24.458-32.917-38.558-57.083-29.867-15.75 5.667-26.834 20.525-27.334 36.809-.833 24.458 18.75 44.558 43.5 44.558zm-3.916 80.458c-41.25-1.733-78.167-20.8-103.25-60.308C-9.288 140.704-5.371 84.004 27.879 44.154c18.917-22.75 43.333-36.716 72.417-41.95 34.25-6.158 66.083.617 94.833 20.117 29.083 19.758 46.833 47.275 53.083 81.975 7.834 44.175-11 90.2-47.333 116.458-22.083 15.942-46.75 23.808-79.083 24.1z"
      fillRule="nonzero"
    />
  </Blank>
)

export default IconDisc
